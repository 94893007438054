export default {
	items: [
		{
			name: 'Dashboard',
			url: '/admin/dashboard',
			icon: 'icon-home',
			badge: {
				variant: 'primary',
			},
		},
		{
			name: 'Account Setup',
			url: '/setup',
			icon: 'icon-settings',
			children: [
				{
					name: 'Company',
					url: '/admin/company',
					icon: 'fa fa-building-o',
				},
				{
					name: 'Storage Location',
					url: '/admin/admin-location',
					icon: 'fa fa-map-marker',
				},
				{
					name: 'User',
					url: '/admin/admin-user',
					icon: 'fa fa-users',
				},
				{
					name: 'Connection',
					url: '/admin/admin-connection',
					icon: 'fa fa-link',
				},
				{
					name: 'Transportation',
					url: '/admin/admin-transportation',
					icon: 'fa fa-truck',
				},
				{
					name: 'Asset Type',
					url: '/admin/admin-asset-type',
					icon: 'fa fa-tags',
				},
			],
		},
		{
			name: 'Transactions',
			url: '/transaction',
			icon: 'icon-list',
			children: [
				{
					name: 'Asset Pool Distribution',
					url: '/admin/admin-asset-pool-distribution',
					icon: 'fa fa-pie-chart',
				},
				{
					name: 'Inventory Session',
					url: '/admin/admin-inventory-session',
					icon: 'fa fa-cubes',
				},
				{
					name: 'Dispatch',
					url: '/admin/admin-dispatch',
					icon: 'fa fa-truck',
				},
				{
					name: 'Receipt',
					url: '/admin/admin-receipt',
					icon: 'fa fa-map-pin',
				},
				{
					name: 'Maintenance',
					url: '/admin/admin-maintenance',
					icon: 'fa fa-wrench',
				},
			],
		},
		{
			name: 'Billing',
			url: '/billing',
			icon: 'icon-credit-card',
			children: [
				{
					name: 'Client Account',
					url: '/admin/admin-client-account',
					icon: 'fa fa-address-book',
				},
				{
					name: 'Billing Report',
					url: '/admin/admin-billing-report',
					icon: 'fa fa-file-text',
				},
			],
		},
		{
			name: 'Reports',
			url: '/reports',
			icon: 'fa fa-line-chart',
			children: [
				{
					name: 'Asset Accountability',
					url: '/admin/admin-asset-accountability',
					icon: 'fa fa-list-alt'
				},
				{
					name: 'Assets with Truckers',
					url: '/admin/admin-assets-with-truckers',
					icon: 'fa fa-truck',
				},
				{
					name: 'Discrepancy Report',
					url: '/admin/admin-discrepancy-reports',
					icon: 'fa fa-flag',
				},
				{
					name: 'Dispatch Alert Logs',
					url: '/admin/admin-dispatch-alerts',
					icon: 'fa fa-bell',
				},
				{
					name: 'Incident Report',
					url: '/admin/admin-incident-reports',
					icon: 'fa fa-warning'
				},
				{
					name: 'Inventory Summary',
					url: '/admin/admin-inventory-summary',
					icon: 'fa fa-table',
				},
			],
		},
		{
			name: 'Support',
			url: '/support',
			icon: 'fa fa-handshake-o',
			children: [
				{
					name: 'Transfer Company',
					url: '/admin/admin-transfer-company-requests',
					icon: 'fa fa-exchange',
				},
				{
					name: 'User Session Logs',
					url: '/admin/admin-user-session-logs',
					icon: 'fa fa-key'
				}
			],
		},
		{
			name: 'Data Importer',
			url: '/data-importer',
			icon: 'fa fa-clipboard',
			children: [
				{
					name: 'Asset Type Importer',
					url: '/admin/asset-type-importer',
					icon: 'fa fa-clipboard',
				},
				{
					name: 'Company Importer',
					url: '/admin/company-importer',
					icon: 'fa fa-clipboard',
				},
				{
					name: 'Dispatch Importer',
					url: '/admin/dispatch-importer',
					icon: 'fa fa-clipboard',
				},
				{
					name: 'Location Importer',
					url: '/admin/storage-location-importer',
					icon: 'fa fa-clipboard',
				},
				{
					name: 'Transportation Importer',
					url: '/admin/transportation-importer',
					icon: 'fa fa-clipboard',
				},
				{
					name: 'User Importer',
					url: '/admin/user-importer',
					icon: 'fa fa-clipboard',
				},
			],
		},
	],
};
